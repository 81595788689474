import React from "react";
import { useStyles } from "./style";
import { SavingsIcon1, SavingsIcon2 } from "../../../assets/icons";
import logo from "../../../assets/images/logo.webp";

const Plans = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div
        className="wrapper"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="1400"
      >
        <div className="left">
          <h1>
            Savings with the aim of Fixing Finance. <span>Forever!</span>
          </h1>
          <p>
            By bringing non-interest banking and other banking tools for the
            benefit of all.
          </p>
          <div className="box">
            <img src={logo} height={"50px"} alt="logo" />
          </div>
        </div>
        <div className="cards">
          <div className="card">
            <div className="header">
              <SavingsIcon1 /> <h6>DoyarSave Plan</h6>
            </div>
            <p>
              This savings plan offers you the flexibility to save daily, weekly
              or monthly towards your set goal. At the end date of your savings
              goal which you'll receive 60% of the profits, following the <a href="https://islamic-banking.com/mudarabah" target="_blank">Mudarabah</a> profit-sharing principle.
            </p>
          </div>
          <div className="card">
            <div className="header">
              <SavingsIcon2 /> <h6>DoyarFixed Plan</h6>
            </div>
            <p>
              This savings plan offers a fixed-term approach to reach your
              financial goals, allowing you to save a fixed amount for a period
              of one month to two years. Your savings remain locked until
              maturity, at which point you'll receive 60% of the profits,
              following the <a href="https://islamic-banking.com/mudarabah" target="_blank">Mudarabah</a> profit-sharing principle.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
