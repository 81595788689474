import React, { useEffect } from "react";
import Content from "../../components/waitlist/content";


const Waitlist = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Waitlist | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Join our waitlist to discover a non-interest approach that truly values you and your financial goals."
      );
      
    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);
    
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Content/>
    </>
  );
};

export default Waitlist;
