import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/careers/hero";
import Benefits from "../../components/careers/benefits";
import Positions from "../../components/careers/positions";
import Pulse from "../../components/careers/pulse";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";

const Careers = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Careers | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "We offer the best workplace, promoting an inclusive culture, employee well-being, and continuous learning. Employees contribute to impactful and meaningful projects in a collaborative and innovative environment."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);
      
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Benefits />
      <Positions />
      <Pulse />

      <JoinBetter />
      <Footer />
    </>
  );
};

export default Careers;
