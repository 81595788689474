import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/privacy/hero";
import Footer from "../../components/footer";
import Content from "../../components/privacy/content";

const TermsAndConditions = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Privacy Policy | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "This privacy policy explains how personal information is collected, used, stored, and disclosed by Doyar."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
