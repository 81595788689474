import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Hero from "../../components/about/hero";
import Change from "../../components/about/change";
import Who from "../../components/about/who";
import Team from "../../components/about/team";

const About = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "About us | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "We believe in revolutionizing the way people interact with their finances. We are committed to providing a unique and ethical banking experience that aligns with your values."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);
      
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Change />
      <Who />
      <Team />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default About;
