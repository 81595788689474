import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/community/hero";
import Footer from "../../components/footer";
import Influence from "../../components/community/influence";
import Perks from "../../components/community/perks";
import Ambassadors from "../../components/community/ambassadors";
import Faqs from "../../components/community/faqs";
import JoinBetter from "../../components/general/join-better";

const Community = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Our Community | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "This is a space where individuals and businesses come together to share experiences, build wealth and get support on the benefits of non-interest Banking."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Influence />
      <Perks />
      <Ambassadors />
      <Faqs />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Community;
