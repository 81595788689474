import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/terms/hero";
import Footer from "../../components/footer";
import Content from "../../components/terms/content";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Terms and Conditions | My Doyar";
    document
    .querySelector('meta[name="description"]')
    .setAttribute(
        "content",
        "These terms and conditions will establish a foundation of your banking association with us Doyar"
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
