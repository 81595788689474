import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/savings/hero";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Plans from "../../components/savings/plans";
import Profit from "../../components/savings/profit";
import Margin from "../../components/savings/margin";
import Aspiration from "../../components/savings/aspiration";

const Savings = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Savings | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Our ethical savings plans are rooted on the principal of non-interest. Your savings are invested in ethical, sustainable and secured financial instrument that guarantees a high profit."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Plans />
      <Profit />
      <Aspiration />
      <Margin />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Savings;
