import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import FaqContent from "../../components/faqs/content";

const Faqs = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "FAQ | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Frequently Asked Questions What are you looking to know about?"
      );
            
    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <FaqContent />

      <Footer />
    </>
  );
};

export default Faqs;
