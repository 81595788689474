import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/security/hero";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Philosophy from "../../components/security/philosophy";
import Report from "../../components/security/report";

const Send = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Security | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Security of your savings and other transactions is a priority for Doyar. We keep your transactions safe and secure with multiple layers of protection."
      );
      
    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Philosophy />
      <Report />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Send;
