import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/contact/hero";
import Footer from "../../components/footer";
import Form from "../../components/contact/form";
import Danger from "../../components/contact/danger";

const Contact = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Contact us | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "We're available 24 hours a day, 7 days a week, to help you. You can use any of our provided means to get in touch with us."
      );

    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);

    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Form />
      <Danger />
      <Footer />
    </>
  );
};

export default Contact;
