import React, { useState, useEffect } from 'react';
import { useStyles } from './content.style';
import logo from "../../../assets/waitlist/doyar-white-logo.svg";
import { FacebookWIcon, InstagramWIcon, LinkedinWIcon, MenuHarmburger, TiktokWIcon, XCirlceIcon, XWIcon } from '../../../assets/icons';
import { Link } from 'react-router-dom';

const Content = () => {
    const classes = useStyles();


    const targetDate = new Date('2024-12-31T23:59:59').getTime();

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        
        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const now = new Date().getTime();
        const difference = targetDate - now;

        let timeLeft = {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00'
        };

        if (difference > 0) {
            timeLeft = {
                days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0'),
                hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
                minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
                seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0')
            };
        }

        return timeLeft;
    }

    const renderDigits = (timeUnit) => {
      return String(timeUnit).split('').map((digit, index) => (
        <h6 key={index}>{digit}</h6>
    ));
    };

    const modalItems = [
        {
            id: 1,
            title: 'SAVINGS',
            content: 'Our ethical savings plans are rooted on the principal of non-interest. Your savings are invested in ethical, sustainable  and secured financial instrument that guarantees a high profit.',
            innerContent: [
                {
                    id: 1,
                    title: 'DoyarSave Plan',
                    content: 'This savings plan offers you the flexibility to save daily, weekly or monthly towards your set goal. At the end date of your savings goal which you\'ll receive 60% of the profits, following the <a href="https://islamic-banking.com/mudarabah" target="_blank">Mudarabah</a> profit-sharing principle.'
                },
                {
                    id: 2,
                    title: 'DoyarFixed Plan',
                    content: 'This savings plan offers a fixed-term approach to reach your financial goals, allowing you to save a fixed amount for a period of one month to two years. Your savings remain locked until maturity, at which point you\'ll receive 60% of the profits, following the <a href="https://islamic-banking.com/mudarabah" target="_blank">Mudarabah</a> profit-sharing principle.'
                }
            ]
        },
        {
          id:2,
          title: 'PAYMENTS',
          content:'Transfer money with ease using our fast, secure, and reliable platform. Built with smart technology, our service ensures each transaction is completed swiftly and safely. Count on our platform designed to make your transfers seamless, efficient, and secure every time.'
        },
        {
          id: 3,
          title: 'NON-INTEREST',
          content:'Non-interest banking operates on principles that prohibit earning or paying interest, focusing instead on ethical investment and profit-sharing methods. This approach promotes fairness and risk-sharing between banks and their customers, fostering financial growth and inclusivity.</br> Our key model within non-interest banking is <a href="https://islamic-banking.com/mudarabah" target="_blank">Mudarabah</a>. The term refers to a form of business contract in which one party brings the funds and the other brings expertise. The proportionate share in profit is determined by mutual agreement. Doyar brings world class expertise in the management of our users funds.<br/> We focus on responsible finance, where we believe in being clear, doing what\'s right, and sharing profits fairly. Our goal is to encourage ethical investments that connect old-fashioned values with modern financial solutions, making the financial system fair and open to everyone.'
      },
      ]

      const [item, setItem] = useState(null);
      const handleModal = (item) => {
        setItem(item);
      }
      const closeModal = () => {
        setItem(null);
      }
const [openMobileMenu, setOpenMobileMenu] = useState(false);
const handleMobileMenu = () => {
  setOpenMobileMenu(!openMobileMenu);
}
    return (
      <React.Fragment>
        <div className={classes.container}>
            <div className='wrapper'>
                <div className='top-bar'>
                  <Link to='/'>
                    <img src={logo} alt='doyar-logo' />
                    </Link>
                    <div className={['top-bar-items', openMobileMenu ? classes.openMobileMenu : ''].join(' ')} >
                        <button className='top-bar-item'
                          onClick={() => handleModal(modalItems[0])}
                        >Savings</button>
                        <button className='top-bar-item'
                          onClick={() => handleModal(modalItems[1])}

                        >Payments</button>
                         <button className='close-icon' onClick={handleMobileMenu}>
                          <XCirlceIcon/>
                        </button>
                        <button className='top-bar-item'
                          onClick={() => handleModal(modalItems[2])}
                        >Non-Interest</button>
                    </div>
                    <div className='empty'></div>
                    <button className='mobile-menu' onClick={handleMobileMenu}>
                      <MenuHarmburger/>
                    </button>
                </div>
                <div className='inner-content'>
                    <div className='img-container'>
                        <div className='icons'>
                            <a href="https://www.x.com/mydoyar" target="_blank" rel="noreferrer"><XWIcon /></a>
                            <a href="https://www.instagram.com/my_doyar" target="_blank" rel="noreferrer"><InstagramWIcon /></a>
                            <a href="https://www.facebook.com/mydoyar" target="_blank" rel="noreferrer"><FacebookWIcon /></a>
                            <a href="https://www.tiktok.com/@mydoyar" target="_blank" rel="noreferrer"><TiktokWIcon /></a>
                            <a href="https://www.linkedin.com/company/my-doyar" target="_blank" rel="noreferrer"><LinkedinWIcon /></a>
                        </div>
                    </div>
                    <div className="content">
                        <h2><span>Non-Interest</span> Banking
                          <br />Has A New
                          <br /> <div className="direction-wrapper">
                            Direction
                            <div className="arrow-container">
                              <div className="arrow"></div>
                            </div>
                          </div> 
                        </h2>
                       
                        <p>The benefits of non-interest banking have been limited to a few. So we decided to share it with you. All on your phone.</p>
                        <div className='counter-container'>
                            <div className='time-unit'>
                                <div className='unit'>
                                    {renderDigits(timeLeft.days)}
                                    <div className='divider'>:</div>
                                </div>
                                <span>Days</span>
                            </div>
                            <div className='time-unit'>
                                <div className='unit'>
                                    {renderDigits(timeLeft.hours)}
                                    <div className='divider'>:</div>
                                </div>
                                <span>Hours</span>
                            </div>
                            <div className='time-unit'>
                                <div className='unit'>
                                    {renderDigits(timeLeft.minutes)}
                                    <div className='divider'>:</div>
                                </div>
                                <span>Minutes</span>
                            </div>
                            <div className='time-unit'>
                                <div className='unit'>
                                    {renderDigits(timeLeft.seconds)}
                                </div>
                                <span>Seconds</span>
                            </div>
                        </div>
                        <p>Join our waitlist to discover a non-interest approach that truly values you and your financial goals. <span className='hashtag'>#ForAllOfUs.</span></p>
                        <div className='button-container'>
                            <span>Click on the button to continue</span>
                            <button onClick={()=>{window.location.href="https://forms.gle/rDWjNZaBz8BKyr1c8"}}>Join Waitlist</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {item && <Modal item={item} onClose={closeModal} />}
      </React.Fragment>
    );
}

const Modal = ({item, onClose}) => {
    const classes = useStyles();
    return (
        <div className={classes.modal}>
            <div className={classes.modalContent}>
              <div className='header'>
                <h2>{item.title}</h2>
                <button className='close-icon' onClick={onClose}>
                  <XCirlceIcon/>
                </button>
              </div>
                <p  dangerouslySetInnerHTML={{ __html:item.content}}></p>
                {item?.innerContent && item?.innerContent.length > 0 && item?.innerContent.map((innerItem) => (
                    <div className='inner' key={innerItem.id}>
                        <h2>{innerItem.title}</h2>
                        <p  dangerouslySetInnerHTML={{ __html:innerItem.content}}></p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Content;
