import { makeStyles } from "@mui/styles";
import { colors } from "../../../styles/colors";
import bg from "../../../assets/savings/plans-bg.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "67px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "40px",
    },

    "& .wrapper": {
      fontFamily: "'Montserrat', sans-serif",
      background: `url(${bg}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      // backgroundColor: "#F4F6F9",
      padding: "60px 43px",
      borderRadius: "20px",
      display: "grid",
      gridTemplateColumns: ".4fr 1fr",
      gap: "39px",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        padding: "40px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        gap: "20px",
      },
      "& .left": {
        position: "relative",
        "& h1": {
          color: "#181817",
          fontSize: "36px",
          fontWeight: 700,
          lineHeight: "50px",
          marginBottom: "16px",
          "& span": {
            color: colors.doyarPeach,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "24px",
            lineHeight: "34px",
            textAlign: "center",
          },
        },
        "& p": {
          color: colors.doyarDark,
          fontSize: "18px",
          lineHeight: "34px",
          fontWeight: 400,
      
          [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
          },
        },
        "& .box": {
          position: "absolute",
          width: "230px",
          height: "230px",
          bottom: "40px",
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
          "& img": {
            // position: "absolute",
            opacity: 0,
          },
          "&:hover": {
            "& img": {
              transition: "all 1s ease-in-out",
              opacity: 1,
            },
          },
        },
      },
      "& .cards": {
        display: "flex",
        flexDirection: "column",
        gap: "51px",
        [theme.breakpoints.down("md")]: {
          gap: "20px",
          "& svg": {
            width: "53px",
            height: "53px",
          },
        },
        "& .card": {
          background: "#fff",
          padding: "26px",
          borderRadius: "20px",
          [theme.breakpoints.down("md")]: {
            padding: "20px",
          },
          "& .header": {
            display: "flex",
            alignItems: "center",
            gap: "26px",
            marginBottom: "26px",
            [theme.breakpoints.down("md")]: {
              gap: "10px",
            },
            "& h6": {
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "34px",
              color: colors.doyarDark,
              [theme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "22px",
              },
            },
          },
          "& p": {
            fontSize: "18px",
            lineHeight: "34px",
            color: colors.doyarDark,
            "& a": {
              color: colors.doyarPeach,
              textDecoration: "none",
            },
          },
        },
      },
    },
  },
}));
