import React, { useEffect, useState } from "react";
import { useStyles } from "./content.style";

const Content = () => {
  const classes = useStyles();
  const [path, setPath] = useState("");
  useEffect(() => {
    function handleHashChange() {
      setPath(window.location.hash);
    }

    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("scroll", handleHashChange);
    handleHashChange(); // Update the path state on initial mount

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="header">
          <h3>Privacy Policy</h3>
          <p>
            This privacy policy (this "Privacy Policy") explains how personal
            information is collected, used, stored, and disclosed by Doyar.
            ("Doyar," "we," "us," and "our")The provisions contained in this
            Privacy Policy supersede all previous notices and statements
            regarding our privacy practices with respect to our services.
            Capitalized terms that are not defined in this Privacy Policy have
            the meaning given to them in our Terms of use. If you do not want
            your information processed in accordance with this Privacy Policy in
            general or any part of it, you should not use our mobile services.
            This policy applies to all users of the Service, including, but not
            limited to users of our App, Website and Developer portal.
          </p>
        </div>
        <div className="content">
          <div className="links">
            <a
              href="#01"
              className={path === "#01" || path === "" ? "active" : ""}
            >
              What information do we collect?
            </a>
            <a href="#02" className={path === "#02" ? "active" : ""}>
              How do we use your information?
            </a>
            <a href="#03" className={path === "#03" ? "active" : ""}>
              Who do we share your information with?
            </a>
            <a href="#04" className={path === "#04" ? "active" : ""}>
              How long do we keep your data?
            </a>
            <a href="#05" className={path === "#05" ? "active" : ""}>
              When do we delete your data?
            </a>
            <a href="#06" className={path === "#06" ? "active" : ""}>
              What are your rights?
            </a>
            <a href="#07" className={path === "#07" ? "active" : ""}>
              How to make a complaint.
            </a>
            <a href="#08" className={path === "#08" ? "active" : ""}>
              Changes to this document.
            </a>
          </div>
          <div className="main-col">
            <div className="col">
              <h4 id="01">What information do we collect?</h4>
              <h6>Information you give us on Doyar Applications</h6>
              <p>
                We collect and use any information you supply when you interact
                with any of our touch points. When you open an account with us,
                you share details like your names, Bank Verification Number
                (BVN), identification documents, address and pictures. By using
                our card or any Doyar Application to transact, you also share
                details of your transactions with us. Additionally, we may
                request explicit permission to see other information like your
                address book, location, photos and data from your phone camera.
                Other details we collect and what we do with them include;
              </p>
              <ul>
                <li>
                  Details you give when you sign up for a Doyar account, like
                  your BVN, names, date of birth, gender, phone number,
                  residential address, and email address are in fulfilment of
                  regulatory requirements.
                </li>
                <li>Your profile picture.</li>
                <li>
                  We collect a video of your face during a liveness check done
                  to confirm that you're the one opening or upgrading your Doyar
                  account. We share this video with a third-party service
                  provider to facilitate our liveness check.
                </li>
                <li>
                  You may choose not to upload a video of your face on the Doyar
                  app, however, this will limit what you can do with your Doyar
                  account as the identification and verification will be
                  incomplete.
                </li>
                <li>
                  We will not share the video of your face that we collect
                  during a liveness check with advertisers or anyone else other
                  than the third-party service provider that facilitates the
                  liveness check process.
                </li>
                <li>
                  If at any time you choose to withdraw your consent for us to
                  use this video for your liveness check, we will delete it.
                  However, please note that this would affect your ability to
                  use your account as certain services are dependent on us
                  processing your video for the liveness check.
                </li>
                <li>
                  Information you give us through the in-app chat so we can help
                  you.
                </li>
              </ul>
              <h6>
                Information you give us when you contact us through other
                channels
              </h6>
              <p>
                If you contact us via other means than the in-app chat, we
                collect the following information so we can answer your
                questions or take action.
              </p>
              <ul>
                <li>
                  The phone number you're calling from and information you give
                  us during the call
                </li>
                <li>
                  The email address you use and the contents of your email (and
                  any attachments) sent to us.
                </li>
                <li>
                  Public details from your social media profile (like Facebook,
                  Instagram or Twitter) if you reach out to us via these
                  platforms, and the contents of your messages or posts to us.
                </li>
              </ul>
              <h6>Information we collect when you use Doyar channels</h6>
              <ul>
                <li>
                  The mobile network operator and the operating system that you
                  use,
                </li>
                <li>Your IP addresses and device ID</li>
                <li>
                  Your phone contacts so you can make airtime purchases or pay
                  contacts on Kuda
                </li>
              </ul>
            </div>
            <div className="col">
              <h4 id="02">How do we use your information?</h4>
              <p>
                The Nigerian Data Protection Regulations 2019 (NDPR) requires
                that we have a lawful basis for processing your personal
                information. At least one of the following lawful basis must
                apply before we process your personal information: contractual
                or legal obligations, legitimate interest of the data
                controller, public interest, vital interest of the data subject
                or consent.
              </p>

              <h6>Contractual Obligation</h6>
              <p>
                We collect certain data from you to fulfill the contract we have
                with you, or to enter into a contract with you. We use this data
                to:
              </p>
              <ul>
                <li>
                  Give you the services we agreed to in line with our terms and
                  conditions.
                </li>
                <li>
                  Send you messages about your account and other services you
                  use if you get in touch, or we need to tell you about
                  something..
                </li>
                <li>
                  Exercise our rights under contracts we've entered into with
                  you, like managing, collecting and recovering money you owe
                  us.
                </li>
                <li>Investigate and resolve complaints and other issues.</li>
              </ul>
              <h6>Legal Duty</h6>
              <p>
                We have to ensure we aren't breaking any laws by banking you by
                preventing illegal activities like money laundering, terrorism
                financing and fraud. To do this, we need your data to;
              </p>
              <ul>
                <li>Confirm your identity when you sign up or get in touch.</li>
                <li>
                  Prevent illegal activities like money laundering, tax evasion
                  and fraud.
                </li>
                <li>
                  Keep records of information we hold about you in line with
                  legal requirements.
                </li>
                <li>
                  Adhere to banking laws and regulations (these mean we
                  sometimes need to share customer details with regulators, tax
                  authorities, law enforcement or other third parties).
                </li>
              </ul>
              <h6>Legitimate Interest of the data controller</h6>
              <p>
                In some instances, we need to use the data you supply us for our
                legitimate interests. This means we're using your data in a way
                that you might expect us to, for a reason which is in your
                interest and doesn't override your privacy, interests or
                fundamental rights and freedoms.
              </p>
              <h6>Consent</h6>
              <p>
                For certain information, we'll ask for your consent. We'll ask
                for your consent to:
              </p>
              <ul>
                <li>
                  Market and communicate our products and services. You can
                  always unsubscribe from receiving these if you want to;
                </li>
                <li>
                  Help protect you against fraud by tracking the location of
                  your phone if you've authorised it;
                </li>
                <li>View your contact list for airtime purchases.</li>
              </ul>
            </div>
            <div className="col">
              <h4 id="03">Who do we share your information with?</h4>
              <p>
                In some instances, we will share the information provided with
                some third parties that we do business with, like card producers
                and credit bureaus, or with law enforcement agencies when
                required. Sharing of confidential customer information with
                these related third parties would be done in a secure manner.
              </p>

              <h4 id="04">How long do we keep your data?</h4>
              <p>
                We keep your information as long as you are a Doyar customer and
                for any additional period as required under applicable law or
                regulations. These retention requirements supersede any right to
                erasure requests under applicable data protection laws. We
                cannot therefore erase your data in relation to your Doyar
                Account until after this time period, however we can assure you
                that your data will be held safely and securely whilst under our
                supervision.
              </p>
              <h4 id="05">When do we delete your data?</h4>
              <p>
                We are basically storing and processing your personal data only
                as long as it is necessary to perform our obligations under the
                agreement with you or as long as the law requires us to store
                it.
                <br />
                That means, if the data is not required anymore for statutory or
                contractual obligations, your data will be deleted.
                <br />
                If you choose to delete your Kuda account, we will delete any
                data you have previously given us, including the video of your
                face uploaded during the liveness check. However this is subject
                to the retention regulations that apply to such data as
                stipulated by the regulators.
              </p>

              <h4 id="06">What are your rights?</h4>
              <p>
                You have the right to access and request the information that we
                have on you in our records. You may also ask us to delete your
                information on our record, restrict the way in which we use your
                personal information, ask that we update the personal
                information we hold about you or correct such personal
                information which you think is incorrect or incomplete, and we
                will grant this request as long as we're legally allowed to. You
                also have the right to object to us using your information for
                our marketing purposes or any additional services we may be
                offering you. Lastly, you may also withdraw any consent you've
                previously given us.
              </p>
              <h4 id="07">How to make a complaint?</h4>
              <p>
                At Doyar, we're extremely committed to respecting and protecting
                your personal information. If you have any worries, reservations
                or complaints about your personal information, please contact
                our Data Protection Officer by:
              </p>
              <ul>
                <li>Sending a message via the app</li>
                <li>
                  Email 1 <u>privacy@doyarbank.com</u>
                </li>
                <li>
                  Email 2 <u>community@doyarbank.com</u>
                </li>
              </ul>
              <h4 id="08">Changes to this document</h4>
              <p>
                This document will be reviewed on a yearly basis, or more
                frequently if occasioned by changes or amendment to applicable
                data protection regulations. If we make any changes, we'll add a
                note to this page and if there are significant changes we'll let
                you know by email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
