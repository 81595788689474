import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Hero from "../../components/send/hero";
import Footer from "../../components/footer";
import JoinBetter from "../../components/general/join-better";
import Cards from "../../components/send/cards";
import Features from "../../components/send/features";

const Send = () => {
  //add meta title and description
  useEffect(() => {
    document.title = "Send/Receive Money | My Doyar";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Manage your finances with our easy account funding and instant money transfers."
      );
      
    const seoImage = document.createElement('meta');
    seoImage.setAttribute('property', 'og:image');
    seoImage.setAttribute('content', 'https://res.cloudinary.com/dhia1jo9u/image/upload/v1732817374/seo-website/mjcrbrjhblzbygfccvh4.png');
    document.head.appendChild(seoImage);
    
    document.body.classList.remove("disable-scroll");
  }, []);
  return (
    <>
      <Navbar />
      <Hero />
      <Cards />
      <Features />
      <JoinBetter />
      <Footer />
    </>
  );
};

export default Send;
